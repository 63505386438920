
@screen lg {
  .sgh-ddm-layout {
    display: flex;
    gap: 4rem;
    width: 100%;
    ::v-deep .submenu-list-container {
      display: flex;
      gap: 4rem;
      flex-shrink: 0;
      .sub-menu-list {
        margin-top:0;
        flex: 1 0 auto;
        &--list-with-space {
          margin-top: 3.7rem;
        }
        .menu-item {
          &-link {
            justify-content: space-between;
            width: auto;
          }
        }
      }
    }
  }
}
