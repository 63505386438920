
@import '../../../styles/variables';

$hamburger-dimension: 1.8rem;

/* hamburger-dimension-closed is equal to -> $hamburger-dimension * √2 */
$hamburger-dimension-closed: 2.4rem;

#hamburger-icon {
  width: $hamburger-dimension;
  height: $hamburger-dimension;
  position: relative;
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 0.1rem;
    width: 100%;
    background: $emperor;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.7s ease-in-out;
    &:nth-child(1) {
      top: 0.3rem;
      transform-origin: right center;
    }
    &:nth-child(2) {
      top: calc(#{$hamburger-dimension} / 2);
      transform-origin: right center;
      transform: translateY(-50%);
    }
    &:nth-child(3) {
      bottom: 0.3rem;
      transform-origin: right center;
    }
  }
  &.open {
    span:nth-child(1) {
      transform: rotate(-45deg);
      width: $hamburger-dimension-closed;
      transform-origin: right top;
      left: -0.6rem;
      top: 0;
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      transform: rotate(45deg);
      transform-origin: right;
      width: $hamburger-dimension-closed;
      bottom: 0;
      left: -0.6rem;
    }
  }
}
