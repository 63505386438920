
.main-menu {
  height: inherit;

  &-content {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    height: inherit;
    flex-direction: column;
     gap:1.6rem;

    @screen lg {
      gap:0;
      flex-direction: initial;
    }
  }
}
