
@import '@styles/variables';

/* new search layout styles */
#right-menu-wrapper {
  position: relative;
  padding: 0 4.8rem;
  display: flex;
  z-index: 1;

  //active layout class
  &.search-layout-visible {
    .btn-cart {
      opacity: 0;
      z-index: 0;
    }

    #hamburger-icon {
      opacity: 0;
      visibility: hidden;
    }

    .close-search-btn {
      opacity: 1;
      border: none;
      background: none;
      &:hover{
       border-radius: 50%;
       background-color: $alabaster;
      }
      
    }

    .left-part {
      left: 1.4rem;
      width: 12.5rem;
      background-color: $alabaster;
      border-radius: 0 2rem 2rem 0;

      .main-menu-right__link {
        left: 8rem;
      }
    }

    .search-input-holder {
      width: calc(100vw - 7.9rem);
      &--focused {
        outline: -webkit-focus-ring-color solid medium;
      }
      @screen lg {
        width: calc(100vw - 30rem);
      }

      @media (min-width: 1800px) {
        width: calc(100vw - 52rem);
      }
    }

    .right-part {
      @screen lg {
        & > * {
          left: -30rem;
        }
      }
    }
  }

  .left-part,
  .btn-cart,
  .menuwrapper {
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: 0;
  }

  .left-part {
    left: 0;
    background-color: transparent;
    transition: all 0.3s linear;
    border-radius: 0;
    z-index: 2;

    .main-menu-right__link {
      position: absolute;
      left: 0;
      top: 0;
      transition: left 0.3s linear;
      z-index: 11;
    }
  }

  .search-input-holder {
    position: absolute;
    height: 4rem;
    width: 0;
    top: 0;
    right: 0;
    z-index: 12;
    overflow: hidden;
    background-color: $alabaster;
    border-radius: 2rem;
    transition: width 0.3s linear;

    @screen mdr {
      right: 0.4rem;
    }
  }

  //right part
  .right-part {
    display: flex;
    position: relative;
    z-index: 2;
    overflow: hidden;
    gap: 0.8rem;

    & > * {
      position: relative;
      left: 0;
      transition: left 0.3s linear;
    }

    &:has(.myaccount-overlay) {
      z-index: 6;
    }
  }

  &.search-layout-visible {
    .right-part {
      z-index: 1;
    }
  }

  .btn-cart,
  .close-search-btn,
  .menuwrapper {
    right: 0;
    z-index: 4;
    transition: opacity 0.3s linear;
  }

  .close-search-btn {
    z-index: 0;
  }
}

.close-search-btn {
  opacity: 0;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  position: absolute;
  border: none;
  outline: none;
  top: 0;

  &.visible {
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
    background-color: $alabaster;
  }

  &:after,
  &:before {
    content: '';
    width: 2.5rem;
    height: 0.1rem;
    background: $emperor;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.menu-opened {
  #right-menu-wrapper {
    .open-btn {
      opacity: 0;
    }
  }
}

.hide-fake-input {
  .menuwrapper {
    visibility: hidden;
  }

  &.menu-opened {
    #right-menu-wrapper {
     > .close-search-btn {
        display: none;
      }
    }
  }
}

.buttons-off {
  .close-search-btn,
  .open-btn {
    pointer-events: none;
  }
}
