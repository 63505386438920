
@import '../../../../styles/fonts';
@import '../../../../styles/variables';

.base-layout-class.scroll {
  position: fixed;
  z-index: 31;

  &.scrolling-up {
    animation: scrollTop 0.3s linear;
    animation-fill-mode: forwards;
    pointer-events: all;
  }
}

.base-layout-class {
  .module-container {
    opacity: 1;
    transition: opacity 1s ease, transform 1s ease;

    &.hide-title {
      opacity: 0;
      transform: translateX(-50%);
      position: absolute;
    }
  }
}

.anchors-container {
  position: relative;
  transition: right 1s ease;
  right: 0;
  top: 10%;

  &.anchors-hidden {
    right: 70%;
    @screen mdr {
      right: 0;
      width: 100%;
    }
  }
}

.sgh-cms-trends-anchor-buttons {
  &--hidden {
    display: none;
  }

  &--visible {
    display: block;
  }
}
  
