
@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';

@mixin figure {
  align-items: center;
  border-radius: 0.5rem;
  display: flex;
  gap: 1rem;
  position: relative;
  height: 9.6rem;
  width: 12.8rem;
  background-color: none;
  @screen lg {
    height: 7.2rem;
    width: 11.925rem;
  }
}

.sgh-cms {
  &__trends-anchor-buttons {
    &--anchor-text {
      height: 2.1rem;
      width: max-content;
      &.rounded {
        height: 4rem;
        padding: 1rem 1.6rem;
        border: solid 1px $alabaster;
        &:hover,
        &:active {
          border: solid 1px $mine-shaft;
        }
      }
    }

    &--chip {
      border-radius: 20rem;
      padding: 0.8rem;
      height: 4rem;
    }

    &--text-background {
      height: 9.6rem;
      width: 12.8rem;
      @screen lg {
        height: 7.2rem;
        width: 11.925rem;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.25);
      }
    }

    &--text-icon {
      height: 2.1rem;
      width: max-content;
      margin-right: 0.8rem;
      &.rounded {
        height: 4rem;
        padding: 1rem 1.6rem;
        border: solid 1px $alabaster;
        &:hover,
        &:active {
          border: solid 1px $mine-shaft;
        }
      }
      
      img {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.8rem;
      }
    }

    &--figcaption {
      @include primaryFont();
      font-weight: 400;
      height: 100%;
      line-height: 1.68rem;
      position: absolute;
      width: 100%;
      font-size: 1.1rem;
      line-height: 1.32rem;
      @screen lgr {
        font-size: 1.4rem;
      }
       &:hover {
        background-color: rgba(0, 0, 0, 0.25);
      }
    }

    &--figure {
      @include figure;
    }

    &--selected-item {
      .rounded {
        border: 0.1rem solid $black;
      }
    }
  }
}
.container {
  overflow: hidden;
}

.base-layout {
  @include figure;
  position: relative;
  transition: all 1s ease-in-out;

  &.sgh-cms__trends-anchor-buttons--chip.scroll {
    border-radius: 20rem;
    padding: 0.8rem;
    height: 4rem;
    width: max-content;
  }

  .background-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
