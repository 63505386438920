
@import '../../../styles/variables';
.sgh-header-top {
  padding: 0 3.2rem 0 4rem;

  @screen lgr {
    padding: 0 1rem;
  }

  a:focus {
    outline-color: transparent;
    outline-offset: 0.2rem;
  }
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  &__text {
    max-width: 60rem;
    margin: 0.8rem 0;
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s ease;
    }
    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
    }
  }
  &__menu {
    .lang {
      margin-left: 0.8rem;
    }
    ul {
      display: flex;
      list-style: none;
      gap: 2.5rem;
      font-size: 1.2rem;
    }
    li,
    a{padding-top: 0.6rem;
      padding-bottom: 0.6rem;}
    a {
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
      &:active {
        opacity: 0.6;
      }
      span {
        display: none;
      }
    }
  }
  .controllerOn {
    transform: rotate(-90deg) !important;
    @screen lgr {
      position: static !important;
    }
  }

  .controllerOnPrevious {
    display: flex;
    transform: rotate(90deg);
    @screen lgr {
      position: static !important;
    }
  }
  .common__icon {
    &--arrow-down-bannertop {
      width: 1.19rem;
      outline-color: transparent;
      outline-offset: 0.2rem;
      @screen lgr {
        position: absolute;
        right: 2.4rem;
        width: 1.58rem;
      }
    }
  }
  .icon-arrow-dx {
    &::before {
      color: inherit;
    }
  }
}

.header-tablet-controllerOn {
  @screen lgr {
    justify-content: center !important;
  }
}
.btn-lang-selector{
  padding-right:0.5rem;
  padding-left:0.5rem;
}
