
@import '@styles/variables.scss';
@import '@styles/mixins/mixins.scss';

.sub-menu-list {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  list-style-type: none;

  @screen lg {
    gap: unset;
  }

  &-wrapper {
    flex: 1 0 auto;
  }

  &-title {
    color: $mine-shaft;
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
    margin-top: 2.4rem;
    line-height:1.8rem;
    font-weight:400;
    letter-spacing: 0.1rem;
    height:1.8rem;
    text-transform: uppercase;
    @screen lg {
      text-transform: none;
      margin-bottom: 0;
      margin-top: unset;
      line-height: 3.4rem;
      font-weight: 600;
      height:auto;
      letter-spacing: normal;
    }
  }

  ::v-deep .menu-item {
    line-height: 2.4rem;
    font-size: 1.6rem;
    @screen lg {
      line-height: unset;
      border:none;
      font-size: 1.2rem;
      height: 1.8rem;
      font-weight: 400;
      margin-bottom: 1.6rem;
      @include primaryFont();
    }
  }
}
