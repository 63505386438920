
@import '../../../../../styles/variables';

ul {
  margin: 0;
  padding: 0.8rem 2.8rem 1.6rem 1.6rem;

  li {
    list-style: none;
    border-bottom: 1px solid $gallery;
    position: relative;

    &:last-child {
      border: none;
    }

    a,
    span {
      text-transform: uppercase;
      display: flex;
      font-size: 1.4rem;
      color: $mine-shaft !important;

      &.profile {
        display: inline-block;
      }
      &.disabled {
        opacity: 0.6;
        height: 100%;
        line-height: 4.4rem !important;
      }
    }
  }
}

@screen mdr {
  ul.internal_menu.dl-submenu {
    padding: 0 2.4rem 2.4rem 2.4rem;

    li {
      margin: 0;
      border: none;

      a,
      span {
        padding: 0 0.8rem 0 0;
      }
    }
  }
}

.incomplete {
  width: 0.8rem;
  height: 0.8rem;
  background: $pumpkin;
  border-radius: 50%;
  display: inline-block;
  vertical-align: text-top;
}

.counter-wishlist {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 1.2rem;
  line-height: 1.2rem;
  margin: -0.7rem 1.2rem 0 -1.35rem;
}

.icon-wishlist {
  font-size: 3rem;
  line-height: 1.2rem;
  align-self: center;
}
