
.sgh-submenus-outlet {
  z-index: 20;
}
// TRANSITION ANIMATION
.slide-fade-move,
.slide-fade-enter-active,
.slide-fade-leave-active {

  transition: transform 0.8s;
  transform: translate3d(0, 0, 0);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translate3d(100%, 0, 0);
}
