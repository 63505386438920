
@import '@styles/variables';

.sub-menu {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overscroll-behavior: none;
  list-style: none;

  @screen lg {
    height: unset;
    position: absolute;
    left: 0;
  }

  &_expanded {
    overflow-y: scroll;
    @media screen and (min-width: 1025px) {
      overflow-y: unset;
    }
  }

  &-wrapper {
    min-height: 100%;
    background-color: $white;
    padding-top: 2.4rem;
    @screen lg  {
      padding: unset;
      height: inherit;
      min-height: unset;
      box-shadow: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.175);
      background-clip: padding-box;
      margin: unset;
      cursor: default;
      display: flex;
      justify-content: center;
    }
    &-back-button {
      border-bottom: 0.1rem solid rgba(34, 34, 34, 0.12);
      display: flex;
      font-size: 1.4rem;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      padding: 0 2.4rem 0.7rem 2rem;
      height: 4rem;
      color: $emperor;
      align-items: center;
      &-label {
        line-height: 2.1rem;
      }
      .chevron {
        margin-right: 1.2rem;
        transform: rotate(180deg);
      }
      @screen lg  {
        display: none;
        background-clip: padding-box;
      }
    }
  }

  &-content {
    //mobile and tablet
    padding: 0 2.4rem 2.4rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    @screen lg  {
      display: block;
      gap: unset;
      width: 100%;
      padding: 3.2rem 4rem;
    }
  }
}

