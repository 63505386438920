
@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';
@import '../../../../../styles/mixins/mixins';

.sgh-cms {
  &__trends-anchor-buttons {
    &--container {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 3.2rem;
      padding: 1.4rem 4rem;
      width: 100%;
      :has(> .sgh-cms__trends-anchor-buttons--align-right) {
        justify-content: space-between;
      }
      @screen mdr {
        gap: 2.4rem;
        padding: 1.4rem 2.4rem;
        display: -webkit-box;
        overflow: scroll;
        overflow-x: auto;
        &.hidden-scrollbar {
          -ms-overflow-style: none; /* Internet Explorer 10+ */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none;
          }
        }
        &:has(.sgh-cms__trends-anchor-buttons--anchor-text) {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          overflow: none;
          overflow-x: none;
          header {
            max-width: none;
          }
        }
      }
      &.scroll {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        &:has(.sgh-cms__trends-anchor-buttons--anchor-text),
        &:has(.sgh-cms__trends-anchor-buttons--anchor-text-icon) {
          padding-top: 1.4rem;
          padding-bottom: 1.4rem;
        }
        ::v-deep .sgh-cms {
          &__trends-anchor-buttons {
            &--module-name {
              max-width: initial;
            }
            &--title {
              font-size: 2.8rem;
            }
          }
        }
      }

    }
  }
}
.skeleton {
  &-img {
    display: flex;
    flex-direction: row;
    height: 6rem;
    @include skeleton-animation;
  }
  &-text {
    display: flex;
    flex-direction: row;
    height: 2rem;
    @include skeleton-animation;
    margin-top: 1rem;
  }
}
