
@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';

.sgh-cms {
  &__trends-anchor-buttons {
    &--text-background {
      height: 7.2rem;
      width: 11.925rem;
    }
  }
}
