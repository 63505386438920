
@import '@styles/variables';
.sgh-cms-collection {
  padding-top: 3.6rem;
  position: relative;
  &-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: min-content;
    align-items: end;
    column-gap: 1.5rem;
    height: 100%;
  }

  &-title {
    color: $mine-shaft;
    font-size: 1.2rem;
    line-height: 3.4rem;
    font-weight: 600;
     height:auto;
    position: absolute;
    top: 0;
  }
  &-link {
    display: flex;
    position: relative;
  }
  &-caption {
    font-size: 1.2rem;
    font-weight: 400;
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    width: 100%;
  }
}
