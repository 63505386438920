
@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';

.sgh-cms {
  &__trends-anchor-buttons {
    &--chip {
      background-color: $gray;
      border-radius: 20rem;
      padding: 0.8rem;
    }
  }
}
