$font-helvetica-files: (
  300: 'HelveticaNeueLTW05-35Thin.woff2',
  400: 'HelveticaNeueLTW05-55Roman.woff2',
  500: 'HelveticaNeueLTW05-65Medium.woff2',
  700: 'HelveticaNeueLTW05-75Bold.woff2',
);
$font-acta-headline-files: (
  300: 'ActaHeadlineLight_normal_normal.woff2',
  400: 'ActaHeadlineBook_normal_normal.woff2',
  500: 'ActaHeadlineBold_normal_normal.woff2',
);

@each $weight, $file in $font-helvetica-files {
  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: $weight;
    font-display: swap;
    src: url('fonts/Helvetica/' + $file) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
}
@each $weight, $file in $font-acta-headline-files {
  @font-face {
    font-family: 'Acta Headline';
    font-style: normal;
    font-weight: $weight;
    font-display: swap;
    src: url('fonts/actaHeadline/' + $file) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
}

@mixin primaryFont() {
  font-family: 'Helvetica Neue', sans-serif;
}

@mixin secondaryFont() {
  font-family: Amiri, 'Helvetica Neue', sans-serif;
}
/* stylelint-disable */
@mixin actaHeadlineFont() {
  font-family: 'Acta Headline', serif !important;
}
/* stylelint-enable */
@mixin actaHeadlineFontTitle() {
  @include actaHeadlineFont();
  letter-spacing: 0.014rem;
  line-height: 2.8rem;
  font-size: 2.8rem;
  font-weight: 400;
}
