
@import '@styles/variables';
@import '@styles/mixins/mixins';

.sgh-main-menu__wrapper {
  .sgh-main-menu {
    height: 6rem;
    background: $white;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    padding: 0 2.9rem 0 4rem;

    @screen lgr {
      padding: 0 1.4rem 0 2rem;
    }

    &.menu-scrolled {
      .menu {
        top: 6rem;
      }
    }

    .skeleton-menu {
      display: flex;

      @screen lgr {
        display: none;
      }

      li {
        margin: 0 3.2rem;
        width: 5rem;
        height: 1.2rem;
        @include skeleton-animation(true);
      }
    }

    .main-menu-center {
      height: inherit;

      .sgh-main-menu {
        &__main-nav {
          &--section {
            font-size: 1.2rem;
            line-height: 1.6rem;
            letter-spacing: 0.1rem;
            color: $mine-shaft;
            cursor: pointer;
            padding: 2.2rem 3.2rem;
            text-transform: uppercase;
            height: 6rem;
            background: none;
            box-shadow: none;

            &:hover {
              border-bottom: 0.2rem solid $mine-shaft;
              background: none;
            }

            &:active {
              color: $gray-3;
            }

            &.hilighted {
              color: $red-berry-2;

              &:hover {
                border-bottom: 0.2rem solid $red-berry;
              }

              &:active {
                color: $soft-red;
              }
            }
          }
        }
      }
    }

    .main-menu-right {
      display: flex;
      gap: 0.8rem;

      ::v-deep .main-menu-right__link {
        width: 4rem;
        height: 4rem;
        border-radius: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:hover {
          background-color: $alabaster;
        }

        &:active {
          background-color: rgba(34, 34, 34, 0.12);
        }
      }
    }

    .menuwrapper button {
      -webkit-appearance: none;
      border: none;
      background: none;
      margin: 0;
      padding: 0;
      width: 4rem;
      height: 4rem;
      border-radius: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &:active {
        background-color: rgba(34, 34, 34, 0.12);
      }
    }

    .counter {
      position: absolute;
      top: 0.6rem;
      right: 0.4rem;
      width: 1.6rem;
      height: 1.6rem;
      color: $white;
      font-size: 0.9rem;
      line-height: 1.6rem;
      font-weight: 500;
      letter-spacing: normal;
      border-radius: 100%;
      text-align: center;
      background-color: $red-berry;
    }
  }
}

.menu {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  right: 0;
  height: calc(100vh - 10rem);
  height: calc(var(--vh, 1vh) * 100 - 10rem);
  width: 100vw;
  top: 10.8rem;

  > div {
    background-color: $alabaster;
    width: 31.5rem;
    height: 100%;
    position: absolute;
    right: 0;
    border-top: solid 1px rgba(34, 34, 34, 0.05);
    transition: all 0.5s;

    &:not(.submenuOpen) {
      overflow-y: scroll;
      overflow-x: hidden;
      overscroll-behavior: none;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 2.4rem 2.2rem 1.6rem 2rem;
      transition: opacity 0.5s;
      opacity: 1;

      .fake-input-active & {
        opacity: 0;
      }
    }

    .primary-menu {
      background-color: white;
      padding: 2.4rem 2.2rem 1.6rem 2rem;
      transition: opacity 0.5s;
      opacity: 1;

      .fake-input-active & {
        opacity: 0;
      }
    }
  }

  .first-wrapper {
    top: 0;

    .fake-input-active & {
      width: 100%;
      top: -6rem;
      height: calc(100% + 6rem);
    }
  }

  .secondary-menu {
    overflow: hidden;
    width: 100%;
    height: auto;
    padding: 1.6rem 1rem 0.5rem;

    &.static-link {
      padding: 2.4rem 1rem 0.8rem;
      border-bottom: solid 0.1rem rgba(34, 34, 34, 0.12);

      li {
        margin-bottom: 0.8rem;

        .common__icon--frame-genius-menu-icon {
          width: 4rem;
          height: 4rem;
          margin: 0;
        }
      }
    }

    ::v-deep li {
      height: 4rem;
      line-height: 4rem;
      margin-bottom: 0.9rem;
      display: flex;
      align-items: center;

      a {
        font-size: 1.6rem;
        line-height: 1.6rem;
        color: $mine-shaft;
        display: flex;
        align-items: center;

        &:active {
          opacity: 0.6;
        }

        &.hilighted {
          color: $red-berry;
        }
      }

      .common__icon {
        margin: 0 1.1rem;
      }
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .fake-input-container {
    background: $white;
    height: 7.2rem;
    border-bottom: 1px solid $alabaster;
  }

  #holder {
    position: relative;
    padding: 1.6rem;
    background: $white;
    top: 0;
    right: 0;
    z-index: 10;
    width: 31.5rem;
    height: 7.2rem;
    overflow: hidden !important;
    transition: all 0.5s;

    .fake-input-active & {
      width: 100%;
      padding: 0.9rem 5.8rem 1.6rem 2rem;

      @screen mdr {
        height: 6rem;
      }

      .close-search-btn {
        top: 0.9rem;
        transform: translateX(0);
      }
    }

    .close-search-btn {
      position: absolute;
      right: 1.4rem;
      top: 1.6rem;
      transform: translateX(200%);
      transition: all 0.5s;
    }
  }

  .inner-wrapper {
    height: 4rem;
    width: 100%;
    background: $alabaster;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5rem;
    padding: 0 1rem 0 2rem;
    line-height: 4rem;
  }

  .fake-placeholder {
    color: $boulder-2;
    font-size: 1.4rem;
  }
}

.myaccount-icon {
  display: inline-block;
}

// TRANSITION ANIMATION
.fade-move,
.fade-enter-active,
.fade-leave-active {
  // transition: all 0.5s;

  & > div {
    // transition: all 0.5s;
    transform: translateX(0);
  }
}

.fade-enter,
.fade-leave-to {
  & > div {
    transform: translateX(100%);
  }
}

.fade-enter-active {
  animation: controllerEnter 0.5s;
}

.fade-leave-active {
  animation: controllerEnter 0.5s reverse;
}

@keyframes controllerEnter {
  from {
    background-color: rgba(0, 0, 0, 0);
  }

  to {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
