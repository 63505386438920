
@import '../../../styles/fonts';
@import '../../../styles/variables';

.sgh-pay-over-time {
  &-popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2.4rem;
    padding: 0 0.8rem;
    @screen lg {
      padding: 0 2.4rem;
    }

    &-title {
      height: fit-content;
      @include actaHeadlineFontTitle();
      text-align: center;
      color: $black;
      margin-bottom: 2.4rem;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 1.6rem;
      padding: 0;
    }

    

    .afterpay,
    .klarna,
    .paypal {
      &-description,
      &-details {
        @include primaryFont();
        color: $black;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2.4rem;
        text-align: start;
      }

      &-separator {
        border-bottom: 0.2rem solid $gray-4;
      }
    }

    

    .icon {
      &--afterpay,
      &--paypal,
      &--klarna {
        width: 4.5rem;
        margin-right: 1.6rem;
      }
    }
  }
}

.payment-logo-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: -0.8rem;

  strong {
    font-size: 1.6rem;
    color: $mine-shaft;
  }
}
