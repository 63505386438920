
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins/mixins';

.image-container {
  position: relative;
  width:100%;

  &-loading {
    width: var(--skeleton-width);
    height: var(--skeleton-height);

    .skeleton {
      @include skeleton-animation;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  
  img {
    width: 100%;
    display: block;
  }
  .text-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 6.7rem 3rem;
  }
  .description{
    @include actaHeadlineFontTitle();
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 3.2rem;
  }
  .pre-title {
    margin-bottom:3.6rem;
  }
  .pre-title,
  .title{
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.4rem;
  }
  .title,
  .description,
  .foot-note{
    margin-bottom:0.8rem;
  }
  .foot-note{
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
  }
}
.cms-text-align {
  &-center {
    text-align: center;
  }
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
}
