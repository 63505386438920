
@import '@styles/variables.scss';

.menu-item {
  &-link {
    line-height: 3.4rem;
    height: auto;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: $mine-shaft;
    @screen lgr {
      line-height: 1.6rem;
      height: 4rem;
      justify-content: space-between;

      &:active {
        color: $gray;
      }
    }
    @screen lg {
      width: fit-content;
      .sub-menu-list & {
        span:first-of-type:hover {
          text-decoration: underline;
        }
      }
    }
    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: -webkit-focus-ring-color auto thin;
    }
  }

  &-badge {
    border-radius: 100px;
    display: inline-block;
    padding: 0 1rem;
    color: $white;
    font-weight: 400;
    line-height: 2.5rem;
    font-size: 1.2rem;
  }

  &--firstLevel {
    cursor: pointer;
    background: none;
    box-shadow: none;
    height: 4rem;
    line-height: 1.904rem;
    display: flex;
    @screen lg {
      height: 6rem;
      display: unset;
      &:hover {
        border-bottom: 2px solid $mine-shaft;
      }
    }
    & > .menu-item-link {
      color: $mine-shaft;
      font-size: 1.4rem;
      letter-spacing: 0.1rem;
      line-height: 1.6rem;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      @screen lg {
        color: $mine-shaft;
        font-size: 1.2rem;
        height: 6rem;
        letter-spacing: 0.1rem;
        border: none;
        padding: 2.2rem 3.2rem;
        cursor: default;
        &--has-url {
          cursor: pointer;
        }
        &:hover {
          text-decoration: none;
        }
      }

      @screen lgr {
        align-items: center;
        width: 100%;
        &:active {
          color: $gray;
        }
      }
    }
  }

  &--highlighted {
    & > .menu-item-link {
      color: $maroon;
    }

    &:hover {
      border-bottom: 2px solid $maroon;
    }
  }
  &--shop-all {
   @screen lgr{
        background-color: $alabaster;
        display:flex;
        align-items:center;
        margin-left:-2rem;
        margin-right:-2.4rem;
        padding-left:2rem;
        height:5rem;
     }
  }
}
