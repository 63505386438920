
@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';

.sgh-cms {
  &__trends-anchor-buttons {
    &--anchor {
      &-text {
        height: 2.1rem;
        width: 7.8rem;
      }
    }
  }
}
