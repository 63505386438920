
@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';
@import '../../../../../styles/mixins/mixins';

.sgh-cms {
  &__trends-anchor-buttons {
    &--align {
      &-right {
        margin-left: auto;
        justify-items: flex-end;
      }
    }
    &--nav-items {
      align-items: center;
      display: flex;
      gap: 0.8rem;
      &:has(.sgh-cms__trends-anchor-buttons--anchor-text),
      &:has(.sgh-cms__trends-anchor-buttons--anchor-text-icon) {
        gap: 1.6rem;
      }
    }
    &--nav {
      @screen mdr {
        overflow: scroll;
        overflow-x: auto;
        scrollbar-width: none;
      }
    }
  }
}
.skeleton {
  display: flex;
  gap: 0.8rem;
  &-text {
    display: flex;
    flex-direction: row;
    height: 7rem;
    width: 100%;
    @include skeleton-animation;
    margin-top: 1rem;
  }
}

