
@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';

.sgh-cms {
  &__trends-anchor-buttons {
    &--figcaption {
      @include primaryFont();
      background: linear-gradient(black 0, transparent 80%);
      color: $white;
      font-size: 1.4rem;
      font-weight: 400;
      height: 100%;
      line-height: 1.68rem;
      position: absolute;
      width: 100%;
      @screen lg {
       font-size: 1.1rem;
       line-height: 1.32rem;
      }
    }
    &--figure {
      align-items: center;
      border-radius: 0.5rem;
      display: flex;
      gap: 1rem;
      position: relative;
      &-image {
        height: 9.6rem;
        width: 12.8rem;
        @screen lg {
          height: 7.2rem;
          width: 11.925rem;
        }
      }
    }
  }
}
