@import '../fonts';

$animation-duration: 1.6s;
$base-color: lighten(#f2f2f2, 3%);
$shine-color: white;
$white-smoke: #efefef;

@mixin animation($animation, $time, $direction: forwards) {
  -moz-animation: $animation $time $direction;
  -webkit-animation: $animation $time $direction;
  animation: $animation $time $direction;
}

@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 60px, $base-color 80px);
  background-size: 600px;
}

@mixin bottomBorder($color: $silver-chalice) {
  border-bottom: 1px solid $color;
}

@mixin boxSizingBorderBox() {
  /* fixes padding issues */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin btn-black() {
  @include primaryFont();
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 50px;
  border: none;
  color: $white;
  line-height: 4rem;
  transition: all 0.6s;
  border-radius: 20px;
  background-color: $mine-shaft;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.6rem;

  &:hover {
    background: $emperor;
    color: $white;
  }
}

@mixin buttonsBackground($xOffset: 0, $yOffset: 0) {
  background-image: url('../assets/images/buttons_sprites.png');
  background-size: 150px;
  background-position: $xOffset, $yOffset;
}

@mixin clearfix() {
  &:before,
  &:after {
    content: ' '; // 1
    display: table; // 2
  }

  &:after {
    clear: both;
  }
}

@mixin loading-content {
  position: relative;
  &:after {
    @include background-gradient();
    animation: shine-lines $animation-duration infinite linear;
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    right: 0;
    bottom: -1px;
    left: 0;
  }
}

@mixin marginleft($marginLeft: 5px) {
  margin-left: $marginLeft;
}
@mixin marginTop($marginTop: 5px) {
  margin-left: $marginTop;
}
@mixin marginright($marginRight: 5px) {
  margin-left: $marginRight;
}
@mixin marginbottom($marginBottom: 5px) {
  margin-bottom: $marginBottom;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &.placeholder {
    @content;
  }
}

@mixin rounded($borderRadius: 2px) {
  -moz-border-radius: $borderRadius;
  -webkit-border-radius: $borderRadius;
  border-radius: $borderRadius;
}

@mixin transform($value) {
  -webkit-transform: $value;
  -ms-transform: $value;
  transform: $value;
}

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin skeleton-animation($dark: false) {
  background-color: $white-smoke;
  position: relative;
  overflow: hidden;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, $white-smoke, $shine-color, $white-smoke);
    animation: shimmer 2s infinite;
    content: '';
  }
  @if $dark {
    background-color: rgba(219, 219, 219, 0.05);
    &::after {
      background-image: linear-gradient(
        90deg,
        rgba(219, 219, 219, 0.05),
        #dbdbdb,
        rgba(219, 219, 219, 0.05)
      );
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
