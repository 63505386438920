
@import '../../../styles/variables';
.sgh-button {
  white-space: nowrap;
  min-width: 12rem;
  height: 4rem;
  border-radius: 10rem;
  font-size: 1.4rem;
  text-align: center;
  text-decoration: none;
  border: none;
  transition: all ease-out 0.3s;
  line-height: 1.14;

  &__anchor {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
.primary {
  @extend.sgh-button;
  color: rgb(255, 250, 250);
  background-color: $mine-shaft;

  &:hover {
    background-color: rgba(34, 34, 34, 0.7);
  }

  &:active {
    background-color: rgba(34, 34, 34, 0.8);
  }

  &:disabled {
    opacity: 0.2;
  }
}
.secondary {
  @extend.sgh-button;
  color: $black;
  border: solid 0.1rem $black;
  background-color: rgba(34, 34, 34, 0);

  &:hover {
    background-color: rgba(34, 34, 34, 0.12);
  }
  &:active {
    background-color: rgba(34, 34, 34, 0.17);
  }

  &:disabled {
    opacity: 0.2;
  }
}

.primary-2023 {
  @extend.sgh-button;
  white-space: inherit;
  color: $white;
  background-color: $mine-shaft;
  --clickable-space-around-button: -0.4rem;
  position: relative;

  &:after {
    content: '';
    left: 0;
    right: 0;
    bottom: var(--clickable-space-around-button);
    top: var(--clickable-space-around-button);
    position: absolute;
  }
  &:hover {
    background-color: $emperor;
  }

  &:active {
    background-color: rgba(34, 34, 34, 0.9);
  }

  &:disabled {
    opacity: 0.2;
  }
  &.dark {
    background-color: $white;
    color: $mine-shaft;
    &:hover {
      background-color: $alabaster;
      color: $mine-shaft;
    }
    &:active {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
}
.ghost-2023 {
  @extend.sgh-button;
  white-space: inherit;
  color: $black;
  border: solid 0.1rem $black;
  background-color: $transparent;
  --clickable-space-around-button: -0.5rem;
  position: relative;
  &:after {
    content: '';
    left: 0;
    right: 0;
    bottom: var(--clickable-space-around-button);
    top: var(--clickable-space-around-button);
    position: absolute;
  }
  &:hover {
    background-color: rgba(34, 34, 34, 0.1);
  }
  &:active {
    background-color: rgba(34, 34, 34, 0.17);
  }
  &:disabled {
    opacity: 0.2;
  }
  &.dark {
    color: $white;
    border: solid 0.1rem $white;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
    &:active {
      background-color: rgba(255, 255, 255, 0.17);
    }
  }
}
.tertiary-2023 {
  @extend.sgh-button;
  white-space: inherit;
  color: $mine-shaft;
  border-bottom: $mine-shaft 0.1rem solid;
  background-color: $transparent;
  border-radius: 0;
  height: 1.5rem;
  width: fit-content;
  min-width: 0 !important;
  width: auto !important;
  padding: 0;
  max-width: fit-content;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  &:hover {
    border-bottom: none;
  }
  &:active {
    opacity: 0.6;
    border-bottom: none;
  }
  &.dark {
    color: $white;
    border-bottom: $white 0.1rem solid;
    &:hover {
      border-bottom: none;
    }
    &:active {
      opacity: 0.6;
      border-bottom: none;
    }
  }
}

.no-border-underlined {
  border:none;
  text-align: left;
  &:hover {
    ::v-deep .no-border-underlined__text {
      border-bottom: none
    }
  }
  &__text {
    border-bottom: $mine-shaft 0.1rem solid;
  }

}

.common__icon--loading-spinner {
  animation: spinner-rotation 1s linear infinite;
  opacity: 1;
}
