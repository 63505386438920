
@import '../../../../../styles/variables';

.user-logged {
  &-icon {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 100%;
    background-color: $mine-shaft;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: 1.4rem;
    line-height: 1;
  }

  &:hover {
    background-color: unset !important;

    .user-logged-icon {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
}

/*  */
// TRANSITION ANIMATION
@screen mdr {
  .fade-move,
  .fade-enter-active,
  .fade-leave-active {
    transition: transform 0.8s;

    ::v-deep .transition-overlay {
      transition: transform 0.8s;
      transform: translateY(0);
    }
  }

  .fade-enter,
  .fade-leave-to {
    ::v-deep .transition-overlay {
      transform: translateY(-100%);
    }
  }

  .fade-enter-active {
    ::v-deep .overlay-bg {
      animation: controllerEnter 0.8s;
      animation-fill-mode: forwards;
      /* When the spec is finished */
    }
  }

  .fade-leave-active {
    ::v-deep .overlay-bg {
      animation: controllerEnter 0.8s reverse;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes controllerEnter {
  from {
    background-color: rgba(0, 0, 0, 0);
  }

  to {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
