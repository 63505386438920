
@import '@styles/variables.scss';
@import '@styles/mixins/mixins.scss';

.cms-placement-switch-content {

  &.cms-columns-1 {
    display: block;
  }

  &.cms-columns-2 {
    display: grid;
    column-gap: 3.5rem;
    grid-template-columns: repeat(2, 1fr);
  }

  &-footer {
    display: grid;
    gap: 3.5rem;
    font-size: 1.2rem;
    font-weight: 400;
    color:$mine-shaft;
     &-item {
       padding-top: 0.8rem;
     }
    &.cms-columns-1 {
      &.is-single-placement {
          max-width: 38rem;
      }
    }
    &.cms-columns-2 {
      grid-template-columns: 1fr 1fr;
    }
  }
}
