
@import '@styles/variables';
.cms-single-banner {
  height: 100%;
  width: fit-content;
  padding-top: 3.6rem;
  position: relative;
  &-link {
    display: block;
    position: relative;
  }
  &-title {
    top:0;
    max-height: 3.6rem;
    font-size: 1.2rem;
    line-height: 3.4rem;
    font-weight: 600;
    height:auto;
    position: absolute;
    color:$mine-shaft;
  }

  &-image{
    .is-background-color{
      height:29.6rem;
      width:38.6rem;
    }
  }
  
}
::v-deep strong {
  font-weight: 500;
}



