
@import '../../../styles/variables';

li,
::v-deep li {
  height: 4rem;
  margin-bottom: 1.6rem;

  a {
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $mine-shaft;
    letter-spacing: 0.1rem;

    &.hilighted,
    span.hilighted {
      color: $red-berry-2;

      &:active {
        color: $soft-red;
      }
    }

    &.shop-all-class {
      background-color: $alabaster;
      position: absolute;
      padding-left: 2rem;
      height: 5rem;
      display: flex;
      left: 0;
      width: 100%;
      align-items: center;
    }

    &:active {
      opacity: 0.6;
    }
  }

  .link-with-badge {
    display: flex;
    justify-content: space-between;
    padding: 0;
    align-items: center;
    height: 100%;
    flex: 1;

    span:last-child {
      height: 2.5rem;
      display: block;
      border-radius: 3.2rem;

      p {
        margin: 0;
        line-height: 2.5rem;
        padding: 0 1rem;
        font-size: 1.2rem;
      }
    }
  }
}

.first-level {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;

  .fake-input-active & {
    .common__icon--arrow-right-menu {
      right: 6rem;
    }
  }

  &:active {
    opacity: 0.6;
  }

  .link-with-badge {
    padding-right: 1.5rem;
  }

  .common__icon--arrow-right-menu {
    position: relative;
    right: 0;
    transition: right 0.5s;
  }
}

.submenu {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: transparent;
  overflow-y: scroll;
  overscroll-behavior: none;

  &-wrapper {
    padding: 2.4rem 2.4rem 2.4rem 2rem;
    background-color: white;

    ::v-deep div {
      h4 {
        text-transform: uppercase;
        font-size: 1.2rem;
        line-height: 1.5;
        letter-spacing: 0.1rem;
        padding-bottom: 0.4rem;
        padding-top: 2.4rem;
        font-weight: normal;
      }

      ul {
        list-style: none;
        padding: 0;
      }

      li {
        align-items: center;
        display: flex;
        margin-bottom: 1.2rem;

        a {
          font-size: 1.6rem;
          text-transform: none;
          letter-spacing: normal;

          &.hilighted {
            color: $red-berry;
          }
        }
      }
    }
    @screen lgr {
      min-height: 100%;
    }
  }

  .return {
    margin: 0 -2.2rem 0 -2rem;
    padding: 0 2.2rem 0.7rem 1.6rem;
    border-bottom: solid 0.1rem rgba(34, 34, 34, 0.12);
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    display: flex;
    align-items: center;
    height: 4rem;

    .common__icon--arrow-right-menu {
      transform: rotate(180deg);
      margin-right: 1.2rem;
    }

    &:active {
      opacity: 0.6;
    }
  }
}

// TRANSITION ANIMATION
.slide-fade-move,
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.8s;

  .submenu-wrapper {
    transition: all 0.8s;
    transform: translateX(0);
  }
}

.slide-fade-enter,
.slide-fade-leave-to {
  .submenu-wrapper {
    transform: translateX(100%);
  }
}
