
@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';

.sgh-cms {
  &__trends-anchor-buttons {
    &--module-name {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      max-width: 22rem;
      @screen lg {
        max-width: 40rem;
      }
    }
    &--pre-title {
      @include primaryFont();
      color: $black;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.2rem;
      order: 1;
      &.text-ligth {
        color: $white !important;
      }
    }
    &--title {
      @include actaHeadlineFontTitle();
      color: $black;
      font-size: 3.2rem;
      font-weight: 400;
      line-height: 3.2rem;
      order: 2;
      &.text-ligth {
        color: $white !important;
      }
      @screen lg {
        font-size: 4rem;
      }
    }
  }
}
