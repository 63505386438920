
.sgh-menu-wrapper {
  a:after {
    right: 1rem;
  }

  .icon-user {
    font-size: 3rem;
    vertical-align: middle;
    margin-right: 0.4rem;
  }

  #login {
    padding-left: 0.8rem;
  }

  #login,
  #registration,
  ::v-deep .dl-submenu a {
    &:after {
      display: none !important;
    }
  }

  ::v-deep a.profile {
    padding-right: 0 !important;
    display: inline-block;
  }

  #registration {
    padding: 1rem;
    margin: 0;
    position: absolute;
    top: 1.6rem;
    right: 1.2rem;
  }
}

.menu-unlogged {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem;

  #login {
    white-space: nowrap;
    padding: 0.6rem;
  }

  #registration {
    white-space: break-spaces;
    top: 0;
    right: 0;
    padding: 1rem;
    position: relative;
    margin: 0.6rem;
    align-self: center;
  }
}
