
@import '../../../styles/variables';

.sgh-header-top__text-wrapper {

  .countdown {
    &__highlighted {
      color: $sangria-2;
    }
  }

  p {
    margin: 0;
    font-size: 1.2rem;
    animation: fade-in 0.5s;

    &:focus {
      outline-color: transparent;
      outline-offset: 0.2rem;
    }

    &:hover {
      text-decoration: underline;
    }

    &:active {
      opacity: 0.6;
    }
  }

  a {
    color: inherit;
    font-size: 1.2rem;
    padding: 0.6rem 0;

    &:focus {
      outline-color: transparent;
      outline-offset: 0.2rem;
    }

    &:hover {
      text-decoration: underline;
    }

    &:active {
      opacity: 0.6;
    }
  }

  @screen mdr {
    max-width: 29.4rem;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
