/*
* Please use this tool for naming colors before adding them: https://chir.ag/projects/name-that-color/
* If a name is duplicate it can be numbered like done with alto.
*/

$alabaster: #f7f7f7;
$alabaster-5: rgba(247, 247, 247, 0.5);
$alabaster-solid: #fafafa;
$alizarin-crimson: #e22e1c;
$alice-blue: #eff6ff;
$alto: #dadada;
$alto-2: #d2d2d2;
$alto-3: #dddddd;
$alto-4: #d8d8d8;
$alto-5: #d9d9d9;
$alto-5-ligth: #D9D9D966;
$alto-6: #d3d3d3;
$alto-7: #b6b6b6;
$alto-8: #d4d4d4;
$alto-light: #dcdcdc;
$anakiwa: #a6c8ff;
$anzac: #ddad42;
$loyal-yellow: #ffd4a1;
$apricot: #ee8176;
$apricot-2: #ed8773;
$athens-gray: #fafafb;
$ligth-gray: #fcfcfc;
$black: #000000;
$black-light: #1b1b1b;
$black-trans: rgba(0, 0, 0, 0.5);
$black-trans-4: rgba(0, 0, 0, 0.4);
$emperor-trans: rgba(34, 34, 34, 0.1);
$emperor-trans-2: rgba(34, 34, 34, 0.17);
$boulder: #767676;
$bright-red: #ab0218;
$burnt-sienna: #ea6456;
$camarone: #015601;
$carissma: #e882c1;
$cinnabar: #e74c3c;
$cobalt: #0042bc;
$concrete: #f3f2f2;
$curious-blue: #3399cc;
$dark-burgundy: #80150a;
$dark-gray: #111111;
$dark-green: #417505;
$di-serria: #d4ac57;
$double-spanish-white: #e8dbbd;
$dove-gray: #666666;
$downy: #6bc9d1;
$dusty-gray: #9b9b9b;
$dusty-gray-2: #979797;
$dusty-gray-3: #999999;
$dusty-gray-4: #959595;
$emperor: #555555;
$emperor-opaque: #55555533;
$emperor-2: #515151;
$emperor-3: #545454;
$emperor-op80: rgba(85, 85, 85, 0.8);
$emperor-op60: rgba(85, 85, 85, 0.6);
$fade-gray: #4a4a4a;
$fade-light-gray: #ffffff7d;
$fade-light-gray-2: #ffffffcc;
$fade-black: #000000b8;
$gallery: #ebebeb;
$gray: #888888;
$gray-2: #828282;
$gray-3: #acacac;
$gray-4: #eceff0;
$gray-shadow: #3e3e3ed4;
$green: #14892b;
$green-leaf: #417505;
$green-select: #167d4a;
$tropical-rain-forest-green: #006952;
$guardsman-red: #cc0000;
$hawkes-blue: #ebf2fe;
$green-success: #006952;
$hilight-pink: #ea9b9b;
$mandy: #e95555;
$maroon:#7B0000;
$medium-red-violet: #b52980;
$mercury: #e9e9e9;
$mercury-2: #e6e6e6;
$mercury-3: #e1e1e1;
$mine-shaft: #222222;
$mine-shaft-01: #2222221a;
$mine-shaft-33: #22222233;
$mine-shaft-6: #22222299;
$mine-shaft-2: #333333;
$mine-shaft-3: #2e2e2e;
$monza: #d0021b;
$mulberry: #d250a1;
$ocean-green: #3cb878;
$ocra: #e8dbbd;
$overlay-grey: #222222cc;
$pumpkin: #ff7714;
$red-berry: #900000;
$red-berry-2: #990000;
$red-light: #ff7777;
$red-rejected: #c94f00;
$royal-blue: #504bd6;
$sangria: #a0000a;
$sangria-2: #ff8080;
$seablue: #0a5598;
$silver: #cccccc;
$silver-sand:#c4c4c4;
$silver-2: #c2c2c2;
$silver-3: #cdcdcd;
$silver-chalice: #a1a1a1;
$silver-chalice-2: #aaaaaa;
$soft-red: #c86161;
$tamarillo: #9a142d;
$tundora: #444444;
$transparent: transparent;
$valencia: #d24637;
$white: #ffffff;
$wild-sand: #f6f6f6;
$wild-sand-light: #f6f6f680;
$pale-pink: #ef867033;
$gray-error: #f4f4f4;
$transparent-gray: #555555b3;
$transparent-white: #ffffff4d;
$btn-gray: #909090;
$yellow: #f1eaa9;
$yellow-2: #f5e5aa;
$gray-48: #7a7a7a;
$white-smoke: #efefef;
$skeleton-gray: #f2f2f2;
$skeleton-gray-dark: #dbdbdb;
$transparent: transparent;
$light-blue-base: #539bb9;
$iron: #e2e2e5;
$botticelli: #d6e2ee;
$boulder-2: #7b7b7b;
$dove-gray-2: #646464;
$tundora-2: #4e4e4e;
$alto-9: #d1d1d1;
